import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Tab, Tabs } from '@mui/material';

import { AddYourGenresCard } from '@/components/cards/AddYourGenresCard';
import GenericBannerCard from '@/components/cards/GenericBannerCard';
import Loading from '@/components/utility/Loading';
import { FeatureFlagNameFormatter } from '@/constants/FeatureFlagEvents';
import useArtist from '@/hooks/artist/useArtist';
import usePlaylistRecommendations from '@/hooks/playlist/usePlaylistRecommendations';
import usePreviousPlaylistPitches from '@/hooks/playlist/usePreviousPlaylistPitches';
import { GenericBannerModel } from '@/models/Banners';
import { PlaylistPitchModel } from '@/models/Playlist';
import { SpotifyChosenPlaylistsModel } from '@/models/Spotify';
import useFeatureFlags from '@/services/FeatureFlag';
import { useLocalStorage } from '@/services/LocalStorage';

import PreviousPlaylistPitches from './PreviousPlaylistPitches';
import RecommendedPlaylists from './RecommendedPlaylists';
import RecommendedPlaylistsByGenres from './RecommendedPlaylistsByGenres';

enum PlaylistTabs {
  PREVIOUS_PLAYLISTS,
  RECOMMENDED_PLAYLISTS,
}

const PitchPlaylistRecommendations = ({
  outputPlaylists,
  canStep,
  playlists,
}: {
  playlists: SpotifyChosenPlaylistsModel[];
  outputPlaylists: (playlists: SpotifyChosenPlaylistsModel[]) => void;
  canStep: (value: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getItem, setItem } = useLocalStorage();

  const { artist, refetchArtist } = useArtist();
  const [value, setTabValue] = useState<number>(PlaylistTabs.PREVIOUS_PLAYLISTS);

  const [chosenPlaylists, setChosenPlaylists] = useState<SpotifyChosenPlaylistsModel[]>(playlists || []);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [noData, setNoData] = useState<boolean>(false);

  const { isPreviousPlaylistsBannerEnabled, getFeatureFlagPayload, isWebGenreEmbeddedRecsEnabled } = useFeatureFlags();
  const [bannerData, setBannerData] = useState<GenericBannerModel>();
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(getItem('hidePreviousPlaylistsBanner') !== 'true');

  const { setValue, watch } = useFormContext<PlaylistPitchModel>();
  const formPlaylistPitches = watch('playlistPitches');

  const POLLING_INTERVAL = 3000;
  const POLLING_COUNT = 10;
  const [count, setCount] = useState<number>(0);

  const { playlistRecommendations, playlistRecommendationsIsLoading } = usePlaylistRecommendations({
    playlistRecommendationsId: artist?.playlistRecommendationId || artist?.playlistRecommendations.id,
  });

  const { previousPlaylistPitches, previousPlaylistPitchesIsLoading } = usePreviousPlaylistPitches();

  const fullyLoaded = !isLoading && !playlistRecommendationsIsLoading && !previousPlaylistPitchesIsLoading;

  useEffect(() => {
    if (artist?.playlistRecommendationId || artist?.playlistRecommendations.id) return setIsLoading(false);
    if (count >= POLLING_COUNT && !artist?.playlistRecommendationId && !artist?.playlistRecommendations.id)
      return setNoData(true);
    setTimeout(async () => {
      await refetchArtist();
      setCount(count + 1);
    }, POLLING_INTERVAL);
  }, [artist, count, refetchArtist]);

  useEffect(() => {
    if (!previousPlaylistPitches) return setTabValue(PlaylistTabs.RECOMMENDED_PLAYLISTS);
    setTabValue(PlaylistTabs.RECOMMENDED_PLAYLISTS);
  }, [previousPlaylistPitches]);

  useEffect(() => {
    outputPlaylists(chosenPlaylists);
  }, [chosenPlaylists, outputPlaylists]);

  useEffect(() => {
    if (chosenPlaylists.length) return;

    const playlists = formPlaylistPitches.flatMap((pitch) => pitch.playlists);
    const uniquePlaylistIds = Array.from(new Set(playlists));

    if (!isWebGenreEmbeddedRecsEnabled) {
      let formChosenPlaylists: SpotifyChosenPlaylistsModel[] = [];

      for (const playlistId of uniquePlaylistIds) {
        const playlist = playlistRecommendations?.recommendations.find((recommendation) => {
          return recommendation.spotifyPlaylistId === playlistId?.spotifyId;
        });
        if (!playlist) continue;

        formChosenPlaylists = [
          ...formChosenPlaylists,
          {
            spotifyPlaylistId: playlist.spotifyPlaylistId,
            recommendationSource: playlist.recommendationSource,
            name: playlist.name,
            curatorName: playlist.curatorDetails.name,
            followers: playlist.stats.followers,
            image: playlist.image,
          },
        ];
      }
      setChosenPlaylists(formChosenPlaylists);
    }
  }, [chosenPlaylists.length, formPlaylistPitches, isWebGenreEmbeddedRecsEnabled, playlistRecommendations]);

  const handleUpdatePlaylist = useCallback(
    (playlist: SpotifyChosenPlaylistsModel) => {
      if (chosenPlaylists.map((item) => item.spotifyPlaylistId).includes(playlist.spotifyPlaylistId)) {
        setValue(
          'playlistPitches',
          formPlaylistPitches.map((pitches) => ({
            ...pitches,
            playlists:
              pitches.playlists && pitches.playlists.filter((pitch) => pitch.spotifyId !== playlist.spotifyPlaylistId),
          }))
        );
        setChosenPlaylists(chosenPlaylists.filter((item) => item.spotifyPlaylistId !== playlist.spotifyPlaylistId));
      } else {
        setValue(
          'playlistPitches',
          formPlaylistPitches.map((pitches) => ({
            ...pitches,
            playlists: pitches.playlists
              ? [
                  ...pitches.playlists,
                  { spotifyId: playlist.spotifyPlaylistId, recommendationSource: playlist.recommendationSource },
                ]
              : [{ spotifyId: playlist.spotifyPlaylistId, recommendationSource: playlist.recommendationSource }],
          }))
        );
        setChosenPlaylists([...chosenPlaylists, playlist]);
      }
    },
    [chosenPlaylists, setValue, formPlaylistPitches]
  );

  useEffect(() => {
    canStep(!!(chosenPlaylists.length > 0));
  }, [canStep, chosenPlaylists]);

  useEffect(() => {
    if (isPreviousPlaylistsBannerEnabled) {
      const data = getFeatureFlagPayload(FeatureFlagNameFormatter('previousPlaylistsBanner'));
      setBannerData(data as GenericBannerModel);
    } else {
      setIsBannerVisible(false);
    }
  }, [isPreviousPlaylistsBannerEnabled, getFeatureFlagPayload]);

  const hideBanner = () => {
    setItem('hidePreviousPlaylistsBanner', 'true');
  };

  return (
    <>
      <h3>{t('PLAYLISTING.RECOMMENDED-PLAYLISTS')}</h3>
      <p className="text-faded mt16">
        {t('PLAYLISTING.RECOMMENDED-PLAYLISTS-DESCRIPTION')}{' '}
        <span
          className="text-white cursor-pointer underline"
          onClick={() => {
            navigate('/profile');
          }}
        >
          {t('PLAYLISTING.KEEP-YOUR-GENRES-UP-TO-DATE')}
        </span>
        <span>{t('PLAYLISTING.KEEP-YOUR-GENRES-UP-TO-DATE-PART-2')}</span>
      </p>
      {previousPlaylistPitches && previousPlaylistPitches?.items?.length > 0 && (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '100%',
            marginTop: '20px',
            marginBottom: '8px',
          }}
        >
          <Tabs value={value} onChange={(_, newValue) => setTabValue(newValue)}>
            <Tab
              value={PlaylistTabs.RECOMMENDED_PLAYLISTS}
              label={t('Recommended playlists')}
              data-testid="recommended-playlists"
            />
            <Tab
              value={PlaylistTabs.PREVIOUS_PLAYLISTS}
              label={t('Previous playlists')}
              data-testid="previous-playlists"
            />
          </Tabs>
        </Box>
      )}
      {!fullyLoaded && !noData && !isWebGenreEmbeddedRecsEnabled && (
        <div className="centered-loading mt48 mb48">
          <Loading />
        </div>
      )}
      {previousPlaylistPitches && value === PlaylistTabs.PREVIOUS_PLAYLISTS && (
        <PreviousPlaylistPitches
          previousPlaylistPitches={previousPlaylistPitches}
          handleUpdatePlaylist={handleUpdatePlaylist}
        />
      )}

      {fullyLoaded &&
        !isWebGenreEmbeddedRecsEnabled &&
        value === PlaylistTabs.RECOMMENDED_PLAYLISTS &&
        playlistRecommendations &&
        playlistRecommendations.recommendations.length > 0 && (
          <div className={!previousPlaylistPitches || previousPlaylistPitches.items.length === 0 ? 'mt48' : ''}>
            {isBannerVisible && previousPlaylistPitches && previousPlaylistPitches?.items?.length > 0 && (
              <GenericBannerCard
                title={bannerData?.title}
                description={bannerData?.description}
                onClose={() => {
                  hideBanner();
                  setIsBannerVisible(false);
                }}
              />
            )}

            <RecommendedPlaylists
              playlistRecommendations={playlistRecommendations}
              chosenPlaylists={chosenPlaylists}
              handleUpdatePlaylist={handleUpdatePlaylist}
            />
          </div>
        )}
      {isWebGenreEmbeddedRecsEnabled && value === PlaylistTabs.RECOMMENDED_PLAYLISTS && (
        <div className={!previousPlaylistPitches || previousPlaylistPitches.items.length === 0 ? 'mt48' : ''}>
          <RecommendedPlaylistsByGenres handleUpdatePlaylist={handleUpdatePlaylist} />
        </div>
      )}
      {((isWebGenreEmbeddedRecsEnabled && artist?.details.genres.length === 0) ||
        (!isWebGenreEmbeddedRecsEnabled && !playlistRecommendationsIsLoading && noData)) &&
        value === PlaylistTabs.RECOMMENDED_PLAYLISTS && (
          <div
            className={
              !previousPlaylistPitches || previousPlaylistPitches.items.length === 0 ? 'mt48 text-left' : 'text-left'
            }
          >
            <div className="flex-w50p">
              <AddYourGenresCard />
            </div>
          </div>
        )}
    </>
  );
};

export default PitchPlaylistRecommendations;
