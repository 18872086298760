import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';

import Loading from '@/components/utility/Loading';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import { Images } from '@/constants/Images';
import numberFormatter from '@/formatters/ShortNumberConverter';
import usePlaylistRecommendationsByGenre from '@/hooks/playlist/usePlaylistReccomendationsByGenre';
import { PlaylistPitchModel, PlaylistPreviousPitchesModel } from '@/models/Playlist';
import { SpotifyChosenPlaylistsModel } from '@/models/Spotify';

import PlaylistGenres from './PlaylistGenres';

const RecommendedPlaylistsByGenres = ({
  handleUpdatePlaylist,
}: {
  handleUpdatePlaylist: (playlist: SpotifyChosenPlaylistsModel) => void;
}) => {
  const { t } = useTranslation();
  const lastItemRef = useRef<HTMLDivElement>(null);
  const [playlists, setPlaylists] = useState<PlaylistPreviousPitchesModel[]>([]);
  const [offset, setOffset] = useState<number>(0);

  const {
    playlistRecommendationsByGenre,
    playlistRecommendationsByGenresTotal,
    playlistRecommendationsByGenreIsLoading,
    sessionId,
  } = usePlaylistRecommendationsByGenre({
    limit: 20,
    offset: offset,
  });

  const { setValue, watch } = useFormContext<PlaylistPitchModel>();
  const formPlaylistPitches = watch('playlistPitches');

  useEffect(() => {
    if (sessionId) {
      setValue('sessionId', sessionId);
    }
  }, [sessionId, setValue]);

  const canPage = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (!playlistRecommendationsByGenresTotal || playlists.length === playlistRecommendationsByGenresTotal) return;
      if (entries[0].isIntersecting && playlists.length < playlistRecommendationsByGenresTotal) {
        setOffset(offset + 20);
      }
    },
    [offset, playlistRecommendationsByGenresTotal, playlists.length]
  );

  useEffect(() => {
    if (playlistRecommendationsByGenre && playlists.length === offset) {
      setPlaylists(playlists.concat(playlistRecommendationsByGenre));
    } else if (playlistRecommendationsByGenre && playlists.length === 0) {
      setPlaylists(playlistRecommendationsByGenre);
    }
  }, [offset, playlistRecommendationsByGenre, playlists]);

  useEffect(() => {
    if (lastItemRef.current) {
      const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        canPage(entries);
      });

      observer.observe(lastItemRef.current);

      return () => observer.disconnect();
    }
  }, [canPage, lastItemRef, offset]);

  if (playlists.length === 0 && playlistRecommendationsByGenreIsLoading) {
    return (
      <div className="centered-loading mt48 mb48">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex gap20 flex-item-wrap jc-center mt20-lg-down">
        {playlists?.map((playlist) => (
          <ButtonComponent
            isCustom
            key={playlist.id}
            className={`flex-w50p-21 cursor-pointer playlist-recommendation-card p0 br24 ${
              formPlaylistPitches[0].playlists?.map((item) => item.spotifyId).includes(playlist.spotifyId)
                ? 'to-do-card-border'
                : 'to-do-card-pre-border'
            }`}
            onClick={() => {
              handleUpdatePlaylist({
                spotifyPlaylistId: playlist.spotifyId,
                recommendationSource: 'recommendation',
                name: playlist.name,
                curatorName: playlist.curatorDetails.name,
                followers: playlist.stats.followers,
                image: playlist.image,
              });
            }}
          >
            <div
              className={`card-inner cursor-pointer h100p w100p br24`}
              ref={playlist === playlists[playlists.length - 1] ? lastItemRef : null}
            >
              <div className="d-flex gap16">
                <div>
                  {playlist.image ? (
                    <object className="playlist-image new" data={playlist.image} type="image/png;image/svg">
                      <img className="playlist-image new" src={Images.playlistIcon} alt="Fallback playlist img" />
                    </object>
                  ) : (
                    <img className="playlist-image new" src={Images.playlistIcon} alt="Fallback playlist img" />
                  )}
                </div>
                <div className="text-left mb-auto">
                  <p className="truncate-2 fw-bold">{playlist.name}</p>
                  <p className="text-faded small truncate-1">by {playlist.curatorDetails.name}</p>
                  <div className="d-flex mt8">
                    <img src={'/images/logos/spotify-logo.svg'} alt="" height={16} className="mt-auto mb-auto" />
                    <a
                      href={playlist.url}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white mt-auto mb-auto"
                    >
                      <p className="ml8 small pt0">Listen on Spotify</p>
                    </a>
                    <Icon className="ml8 fs-sm text-faded pt4">open_in_new</Icon>
                  </div>
                </div>
                <div className="ml-auto">
                  {formPlaylistPitches[0].playlists?.map((item) => item.spotifyId).includes(playlist.spotifyId) ? (
                    <div>
                      <Icon className="text-blue">check_circle</Icon>
                    </div>
                  ) : (
                    <div>
                      <Icon className="material-symbols-outlined text-faded">circle</Icon>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt16">
                <PlaylistGenres genres={playlist.genres} />
              </div>

              <div className="d-flex mt16 gap4">
                <div className="flex-w50p-2">
                  <p className="fw-bold"> {numberFormatter(playlist.stats.followers)}</p>
                  <p className="small text-faded">{t('INSIGHTS-PAGE.COMMON.FOLLOWERS')}</p>
                </div>
                <div className="vertical-divider faded"></div>
                <div className="flex-w50p-2">
                  <p className="fw-bold">{playlist.stats.trackCount}</p>
                  <p className="small text-faded">{t('PLAYLISTING.SONGS')}</p>
                </div>
              </div>
            </div>
          </ButtonComponent>
        ))}
        {playlistRecommendationsByGenreIsLoading && playlists.length > 0 && (
          <div className="centered-loading mt48 mb48">
            <Loading size="small" />
          </div>
        )}
      </div>
    </>
  );
};

export default RecommendedPlaylistsByGenres;
